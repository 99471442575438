import { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { Icon } from "@iconify/react";

import SharpeLogoWhite from "../images/sharpe-logo-white.svg";

import ShimmerButton from "./magicui/shimmer-button";
import GlobalSearch from "./GlobalSearch";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "lucide-react";
import { NavMenu } from "./NavMenu";

// const CustomTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme, mode }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     color: mode === "light" ? "#000" : "#98ECFF",
//     border: "1px solid #98ECFF",
//     background: mode === "light" ? "#98ECFF" : "transparent",
//     padding: "8px 16px",
//     fontSize: "14px",
//     cursor: "default",
//     fontFamily: "Inter, sans-serif",
//     "&:hover": {
//       border: "1px solid #98ECFF",
//     },
//     textTransform: "capitalize",
//     borderRadius: "0",
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     color: mode === "light" ? "#98ECFF" : "#98ECFF",
//   },
// }));

const tradingProducts = [
  {
    name: "Perp DEX",
    href: "http://trade.sharpe.ai",
    icon: "material-symbols:sync-outline",
    description: "Trade perpetual contracts seamlessly with deep liquidity.",
  },
  {
    name: "Bridge",
    href: "http://bridge.sharpe.ai",
    icon: "material-symbols:swap-calls",
    description: "Transfer coins across different chains at lowest fees.",
  },
  {
    name: "Portfolio",
    href: "/portfolio",
    icon: "material-symbols:public",
    description: "Monitor your crypto assets across various platforms.",
  },
  {
    name: "Earn",
    href: "https://earn.sharpe.ai/",
    icon: "material-symbols:account-balance-outline",
    description:
      "Expertly curated DeFi strategies in self-custodial environment.",
  },
  {
    name: "Swap",
    href: "https://swap.sharpe.ai/",
    icon: "material-symbols:compare-arrows",
    description: "Swap aggregator for Instant token swaps at best rates.",
  },
  {
    name: "NFT Marketplace",
    href: "https://nft.sharpe.ai/",
    icon: "ph:image",
    description: "Buy your next NFT at best prices globally.",
  },
  {
    name: "Onramp",
    href: "https://onramp.sharpe.ai/",
    icon: "ph:money",
    description: "Fiat-to-crypto aggregator for best on-ramping rates.",
  },
  {
    name: "Get Gas",
    href: "https://getgas.sharpe.ai/",
    icon: "material-symbols:local-gas-station-outline",
    description: "Get necessary gas fees for transactions on various chains.",
  },
];

const marketData = [
  {
    name: "Firehose",
    href: "/firehose",
    icon: "material-symbols:local-fire-department-outline",
    description: "Crypto’s real-time data stream to stay up-to date.",
  },
  {
    name: "Screener",
    href: "/screener",
    icon: "material-symbols:bid-landscape-outline",
    description: "Comprehensive data to help you find your new crypto gem.",
  },
  {
    name: "Charting",
    href: "/charting/",
    icon: "material-symbols:stacked-line-chart",
    description: "Best charting tool in Crypto, powered by Tradingview.",
  },
  {
    name: "Options",
    href: "/options",
    icon: "material-symbols:widgets-outline",
    description: "Options trading, options chain, vol surface data and more.",
  },
  {
    name: "Futures",
    href: "/futures",
    icon: "material-symbols:acute-outline",
    description: "Real-time data analytics for crypto futures data.",
  },
  {
    name: "Funding Rates",
    href: "/fundingrate",
    icon: "material-symbols:money-outline",
    description: "Monitor funding rates for every perpetual trading platform.",
  },
  {
    name: "Indices",
    href: "/indices",
    icon: "material-symbols:analytics-outline",
    description: "Track the performance of various crypto indices.",
  },
  {
    name: "Narratives",
    href: "/narratives",
    icon: "ph:eye",
    description: "Stay updated with the latest market narratives and trends.",
  },
  {
    name: "NFTs",
    href: "/nft",
    icon: "material-symbols:imagesmode-outline",
    description:
      "Explore the NFT market for emerging trends and opportunities.",
  },
  {
    name: "ETFs",
    href: "/etf",
    icon: "material-symbols:local-atm-outline",
    description:
      "Granular data on Crypto ETFs across Prices, Holdings and Flow.",
  },
  {
    name: "Public Companies",
    href: "/publiccos",
    icon: "material-symbols:data-thresholding-outline",
    description: "Analyse public companies of crypto in TradFi.",
  },
  {
    name: "Macro",
    href: "/macro",
    icon: "material-symbols:monitoring",
    description: "Macroeconomic tracker for better decision-making.",
  },
  {
    name: "CME",
    href: "/cme",
    icon: "material-symbols:language",
    description: "Access data for BTC, ETH on Chicago Mercantile Exchange.",
  },
];

const communityResources = [
  {
    name: "Documentation",
    href: "https://docs.sharpe.ai/",
    icon: "material-symbols:description-outline",
    description:
      "Access comprehensive documentation for all Sharpe AI products.",
  },
  {
    name: "X/Twitter",
    href: "https://x.com/SharpeLabs",
    icon: "ri:twitter-x-fill",
    description: "Follow on X for all real-time updates and news.",
  },
  {
    name: "Blog",
    href: "https://sharpeai.medium.com/",
    icon: "material-symbols:article-outline",
    description: "Read in-SAI-tful articles and analysis by Sharpe AI.",
  },
  {
    name: "Discord",
    href: "https://discord.com/invite/tFAvMTw6Hx",
    icon: "ic:baseline-discord",
    description: "Join Discord community for discussions, network and support.",
  },
  {
    name: "Sharpe Signals",
    href: "https://twitter.com/SharpeSignals",
    icon: "carbon:connection-signal",
    description: "Actionable trading signals powered by AI.",
  },
];

function NavSection({ title, items, mode }) {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef(null);
  // const router = useRouter()

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleItemClick = (href) => {
    if (title === "Market Data" || href === "/portfolio") {
      const baseUrl = "https://sharpe.ai/";
      // router.push(href)
      window.open(`${baseUrl}${href}`, "_self");
    } else {
      window.open(href, "_self");
    }
    setIsOpen(false);
  };

  return (
    <Popover className="relative">
      <PopoverButton
        className={`inline-flex items-center gap-x-1 text-sm leading-6 ${
          mode === "light" ? "text-neutral-700" : "text-neutral-300"
        } hover:${
          mode === "light" ? "text-black" : "text-white"
        } focus:outline-none focus:ring-0 focus:border-none active:ring-0 active:border-none`}
      >
        <span>{title}</span>
        <ChevronDownIcon aria-hidden="true" className="h-4 w-4" />
      </PopoverButton>
      <PopoverPanel
        transition
        className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div
          className={`w-screen max-w-md flex-auto overflow-hidden rounded-b-3xl rounded-t-3xl ${
            mode === "light" ? "bg-white" : "bg-[#0E0E10]"
          } text-sm leading-6 shadow-lg ring-1 ${
            mode === "light" ? "ring-neutral-200" : "ring-[#27272A]"
          } lg:max-w-3xl`}
        >
          <div className="grid grid-cols-1 max-h-[600px] overflow-y-auto gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
            {items.map((item) => (
              <div
                key={item.name}
                className={`group relative flex gap-x-6 rounded-lg p-4 ${
                  mode === "light"
                    ? "hover:bg-neutral-100"
                    : "hover:bg-[#111113]"
                }`}
              >
                <div
                  className={`mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg ${
                    mode === "light"
                      ? "group-hover:bg-white"
                      : "group-hover:bg-[#0e0d0d]"
                  }`}
                >
                  <Icon
                    icon={item.icon}
                    className={`h-6 w-6 ${
                      mode === "light"
                        ? "text-neutral-500 group-hover:text-black"
                        : "text-neutral-500 group-hover:text-white"
                    }`}
                  />
                </div>
                <div>
                  <a
                    onClick={() => handleItemClick(item.href)}
                    className={`font-semibold ${
                      mode === "light"
                        ? "text-neutral-900 group-hover:text-black"
                        : "text-white group-hover:text-white"
                    } cursor-pointer`}
                  >
                    {item.name}
                    <span className="absolute inset-0" />
                  </a>
                  <p
                    className={`mt-1 ${
                      mode === "light" ? "text-neutral-500" : "text-neutral-400"
                    }`}
                  >
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {title === "Trading Products" && (
            <div
              className={`${
                mode === "light" ? "bg-neutral-100" : "bg-[#111113]"
              } px-8 py-6 cursor-pointer`}
              onClick={() => window.open("https://stake.sharpe.ai/", "_self")}
            >
              <div className="flex items-center gap-x-3">
                <h3
                  className={`text-sm font-semibold leading-6 ${
                    mode === "light" ? "text-neutral-800" : "text-neutral-300"
                  }`}
                >
                  Stake to Earn 35% APY
                </h3>
                <p
                  className={`rounded-full ${
                    mode === "light" ? "bg-neutral-200" : "bg-neutral-500/10"
                  } px-2.5 py-1.5 text-xs font-semibold ${
                    mode === "light" ? "text-neutral-800" : "text-white"
                  }`}
                >
                  New
                </p>
              </div>
              <p
                className={`mt-2 text-sm leading-6 ${
                  mode === "light" ? "text-neutral-600" : "text-neutral-500"
                }`}
              >
                Sharpe Staking allows users to stake their $SAI tokens and earn
                rewards while contributing to the Sharpe AI ecosystem.
              </p>
            </div>
          )}
        </div>
      </PopoverPanel>
    </Popover>
  );
}

export default function Header({ onModeChange }) {
  const [mode, setMode] = useState("dark");
  // const [sharpePoints, setSharpePoints] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  // const isSmallScreen = useIsSmallScreen(600)
  const mainWebsiteApiUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PUBLIC_API_URL
      : process.env.REACT_APP_PUBLIC_LOCAL_API_URL;

  const handleSearchInputClick = () => {
    setOpenSearch(true);
  };
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };

  console.log("mode", mode);
  // Function to toggle mode
  const toggleMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
    onModeChange(newMode);
  };

  // useEffect(() => {
  //   const fetchSharpePoints = async () => {
  //     try {
  //       const points = await getSharpePointsForUser(walletAddress);
  //       setSharpePoints(points);
  //     } catch (error) {
  //       console.error("Error fetching Sharpe points:", error);
  //     }
  //   };

  //   if (walletAddress) {
  //     fetchSharpePoints();
  //   }
  // }, [walletAddress]);

  const renderIcon = () => {
    if (mode === "light") {
      return (
        <Icon
          icon="tdesign:mode-dark"
          width="22"
          height="22"
          style={{ color: "rgb(74 74 74)" }}
        />
      );
    } else {
      return (
        <Icon
          icon="iconamoon:mode-light"
          width="22"
          height="22"
          style={{ color: "#cfcfcf" }}
        />
      );
    }
  };
  const icon = renderIcon();

  const handleKeydown = useCallback(
    (event) => {
      // ** Shortcut keys to open searchbox (Ctrl + /)
      if (
        !openSearch &&
        event.which === 191 &&
        (event.ctrlKey || event.metaKey)
      ) {
        setOpenSearch(true);
      }
    },
    [openSearch]
  );

  const handleKeyUp = useCallback(
    (event) => {
      // ** ESC key to close searchbox
      if (openSearch && event.keyCode === 27) {
        setOpenSearch(false);
      }
    },
    [openSearch]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp, handleKeydown]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        margin: "0px",
        alignItems: "center",
        padding: '10px 20px'
      }}
    >
      <div className="flex items-center gap-2">
      <a href="https://sharpe.ai/" style={{ textDecoration: "none" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={SharpeLogoWhite}
              alt="Sharpe AI"
              style={{ height: '100%', width: '130px' }} 
            />
          </Box>
        </a>
        <div
          class="hidden lg:flex max-w-md mx-auto"
          onClick={handleSearchInputClick}
        >
          <div
            className={`search-box relative flex items-center cursor-pointer border ${
              mode === "light" ? "border-neutral-300" : "border-[#27272a]"
            } px-2 py-0 w-full h-10 rounded-lg focus-within:shadow-lg bg-transparent overflow-hidden`}
          >
            <div className="flex items-center">
              <div
                className={`grid place-items-center h-full w-12 ${
                  mode === "light" ? "text-neutral-500" : "text-neutral-300"
                }`}
              >
                <Icon
                  icon="fluent:search-16-regular"
                  width="22"
                  height="22"
                  style={{
                    color: mode === "light" ? "#4a4a4a" : "#fff",
                    cursor: "pointer",
                  }}
                />
              </div>
              <span
                className={`peer h-full w-full outline-none bg-transparent text-sm ${
                  mode === "light" ? "text-neutral-500" : "text-neutral-400"
                } pr-2 pl-1`}
              >
                Search
              </span>
              <p
                className={`text-sm flex items-center gap-1 ${
                  mode === "light" ? "text-neutral-500" : "text-neutral-500"
                }`}
              >
                Press{" "}
                <kbd
                  className={`pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded  ${
                    mode === "light"
                      ? "bg-neutral-300 border border-neutral-600"
                      : "bg-[#27272A]"
                  } px-1.5 font-mono text-[10px] font-medium ${
                    mode === "light" ? "text-neutral-600" : "text-neutral-300"
                  } opacity-100`}
                >
                  <span className="text-md">⌘</span>/
                </kbd>
              </p>
            </div>
          </div>
        </div>
      </div>
      <NavMenu
        apiUrl={mainWebsiteApiUrl}
        mode={mode}
        customClasses={{
          container: "custom-container-class",
          navSection: {
            button: "custom-button-class",
          },
        }}
      />
      <div style={{ display: "flex", gap: "8px",  }}>
        <div className="z-10 flex items-center justify-center">
          <ShimmerButton
            className="shadow-2xl"
            onClick={() => window.open("https://stake.sharpe.ai/", "_self")}
            mode={mode} // Pass the current mode to ShimmerButton
          >
            <span
              className={`whitespace-pre-wrap text-center py-0 text-sm font-medium leading-none tracking-tight lg:text-[14px] ${
                mode === "light" ? "text-black" : "text-white"
              }`}
            >
              Earn $SAI
            </span>
          </ShimmerButton>
        </div>
        {/* <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild className="bg-transparent rounded-md">
              <Button
                variant="outline"
                size="icon"
                className={`
        bg-transparent 
        rounded-[6px] 
        h-[44px]
        w-[44px]
        hover:bg-transparent 
        ${mode === "dark" ? "border-neutral-700" : "border-neutral-400"} 
        ${mode === "dark" ? "dark:bg-black" : ""}
      `}
                onClick={toggleMode}
              >
                {icon}
              </Button>
            </TooltipTrigger>
            <TooltipContent
              className={`border-neutral-800 rounded-[4px] bg-transparent ${
                mode === "dark" ? "border-neutral-800" : "border-neutral-200"
              } ${mode === "dark" ? "text-neutral-200" : "text-black"}`}
            >
              <p
                className={`text-[12px]
               ${mode === "dark" ? "text-white" : "text-black"},`}
              >
                Switch to {mode === "light" ? "dark" : "light"} mode
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider> */}
        {/* <Box sx={{ display: "flex", gap: "0px" }}>
          <TooltipProvider delayDuration={200}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className={`
        bg-transparent 
        rounded-[6px] 
        h-[44px]
        w-[144px]
        font-normal
        hover:bg-transparent 
        
        ${mode === "dark" ? "border-neutral-700" : "border-neutral-400"} 
        ${mode === "dark" ? "text-neutral-50" : "text-neutral-900"} 
        ${mode === "dark" ? "hover:text-neutral-50" : "hover:text-neutral-900"} 
        ${mode === "dark" ? "dark:bg-transparent" : ""}
      `}
                >
                  {Number(sharpePoints) === 0
                    ? "--"
                    : `${numeral(Number(sharpePoints)).format("0.00a")}`}
                  &nbsp;
                  <span>PTS</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent
                className={`border-neutral-800 mr-4 rounded-[4px] bg-transparent ${
                  mode === "dark" ? "border-neutral-800" : "border-neutral-200"
                } ${mode === "dark" ? "text-neutral-200" : "text-black"}`}
              >
                <p
                  className={`text-[12px] w-[260px] text-center leading-[15px]
               ${mode === "dark" ? "text-white" : "text-black"},`}
                >
                  It may take upto 2 hours for Sharpe points to be updated after
                  a successful transaction is completed.
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </Box> */}
      </div>
      <GlobalSearch
        openSearch={openSearch}
        handleClose={handleCloseSearch}
        mode={mode}
      />
    </div>
  );
}
